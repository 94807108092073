import React, {Component} from "react"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {Button} from "pages/sign-in/parts/Button"
import {LoginWrapper} from "components/LoginWrapper"
import {Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "../../lib/functions/findErrors"
import {getUrlToken} from "../../lib/functions/route"
import styles from "./ResetPassword.module.css"
import Swal from "sweetalert2"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    password: {
        ...mandatory,
        length: {minimum: 3}
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    }
}

export interface ResetPasswordUser {
    password?: string
    confirm_password?: string
}

@observer
export class ResetPassword extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private user: ResetPasswordUser = {
        password: "",
        confirm_password: ""
    }

    async componentDidMount() {
        const stringToken = getUrlToken()
        await this.props.model.checkResetPasswordLink(stringToken)
    }

    private isValid = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        this.errors = errors
        return isValid
    }

    public render() {
        const user = this.user

        return (
            <React.Fragment>
                {this.props.model.isExpired ? (
                    <LoginWrapper>
                        <div className={styles.root}>
                            <h2 className={styles.h2}>Invalid token or expired.</h2>
                            <article className={styles.loginArea}>
                                <div className={styles.inner}>
                                    <p className={styles.text}>This page could not be loaded.</p>
                                    <Button
                                        text="Back To Login"
                                        onClick={() =>
                                            this.props.model.getHistory().push("/sign-in")
                                        }
                                        className={styles.button}
                                    />
                                </div>
                            </article>
                        </div>
                    </LoginWrapper>
                ) : (
                    <LoginWrapper>
                        <div className={styles.root}>
                            <h2 className={styles.h2}>Reset Your Password</h2>
                            <div className={styles.warning}>
                                <span className={styles.dot}>*</span>{" "}
                                <span>
                                    <i>
                                        {" "}
                                        You have 30 mins to change your password, before this link
                                        expires.
                                    </i>
                                </span>
                            </div>

                            <article className={styles.loginArea}>
                                <div className={styles.inner}>
                                    <p className={styles.text}>Please enter your new password.</p>
                                    <Label
                                        text="New password"
                                        required
                                        className={styles.inputPass}
                                        error={this.errors["password"]}>
                                        <Input
                                            onChange={(v) => {
                                                user.password = v
                                            }}
                                            value={user.password}
                                            type="password"
                                        />
                                    </Label>

                                    <Label
                                        text="New password confirmation"
                                        required
                                        className={styles.inputPass}
                                        error={this.errors["confirm_password"]}>
                                        <Input
                                            onChange={(v) => {
                                                user.confirm_password = v
                                            }}
                                            value={user.confirm_password}
                                            type="password"
                                        />
                                    </Label>

                                    <Button
                                        text="Reset Password"
                                        onClick={this.resetPassword.bind(this)}
                                        className={styles.button}
                                    />

                                    <Button
                                        text="Back To Login"
                                        onClick={() =>
                                            this.props.model.getHistory().push("/sign-in")
                                        }
                                        className={styles.button}
                                    />
                                </div>
                            </article>
                        </div>
                    </LoginWrapper>
                )}
            </React.Fragment>
        )
    }

    private async resetPassword() {
        if (this.isValid()) {
            const model = this.props.model
            const stringToken = getUrlToken()
            const checkResponse = await model.checkResetPasswordLink(stringToken)
            if (!model.isExpired) {
                const response = await model.updatePassword({
                    password: this.user.password,
                    token: stringToken
                })
                if (response.success) {
                    Swal.fire({
                        icon: response.success ? "success" : "warning",
                        text: `${response.message}`
                    })
                    // alert(`${response.message}`)
                    this.props.model.getHistory().push("/sign-in")
                }
            } else {
                const {
                    errors: [{message}]
                } = checkResponse
                // alert(`${message}`)
                Swal.fire({
                    icon: "warning",
                    text: `${message}`
                })
            }
        }
    }
}
